import React from 'react'
import { useNavigate } from 'react-router-dom'
import FeatureCard from './FeatureCard'
import Header from './Header'

interface Feature {
  title: string
  description: string
  path: string
  icon: string
  primaryMetric: string
  primaryLabel: string
  secondaryMetrics: Array<{
    label: string
    value: string
  }>
  metricColor: string
  comingSoon?: boolean
}

interface Section {
  title: string
  features: Feature[]
}

const HomePage: React.FC = () => {
  const navigate = useNavigate()

  const sections: Section[] = [
    {
      title: 'Business configuration',
      features: [
        {
          title: 'Knowledge Base',
          description: 'Business documentation and workflows',
          path: '/knowledge',
          icon: '📚',
          primaryMetric: '234',
          primaryLabel: 'articles',
          secondaryMetrics: [
            { label: 'Workflows', value: '12' },
            { label: 'Last updated', value: '2h ago' },
          ],
          metricColor: 'text-blue-600',
          comingSoon: true,
        },
        {
          title: 'Business Profile',
          description: 'Business details and preferences',
          path: '/profile',
          icon: '🏢',
          primaryMetric: '3',
          primaryLabel: 'locations',
          secondaryMetrics: [
            { label: 'Visitors (30d)', value: '285' },
            { label: 'Bounce rate', value: '30%' },
          ],
          metricColor: 'text-blue-600',
          comingSoon: false,
        },
        {
          title: 'Team Management',
          description: 'Team members and permissions',
          path: '/team',
          icon: '👥',
          primaryMetric: '12',
          primaryLabel: 'members',
          secondaryMetrics: [
            { label: 'Online', value: '8' },
            { label: 'Roles', value: '4' },
          ],
          metricColor: 'text-blue-600',
          comingSoon: true,
        },
      ],
    },
    {
      title: 'Active Channels',
      features: [
        {
          title: 'Inbound Calls',
          description: 'AI-powered call reception',
          path: '/reception',
          icon: '📞',
          primaryMetric: '4',
          primaryLabel: 'live calls',
          secondaryMetrics: [
            { label: 'Automation rate (30d)', value: '80%' },
            { label: 'Calls today (24h)', value: '28' },
          ],
          metricColor: 'text-green-600',
        },
        {
          title: 'Outbound Dialler',
          description: 'AI-powered outbound campaigns',
          path: '/dialer',
          icon: '📲',
          primaryMetric: '2',
          primaryLabel: 'ongoing campaigns',
          secondaryMetrics: [
            { label: 'Answer rate (30d)', value: '50%' },
            { label: 'Effective rate (30d)', value: '34%' },
          ],
          metricColor: 'text-green-600',
        },
        {
          title: 'Chatbots',
          description: 'Multi-channel AI chatbots',
          path: '/chatbot',
          icon: '💬',
          primaryMetric: '28',
          primaryLabel: 'live chats',
          secondaryMetrics: [
            { label: 'Automation rate (30d)', value: '76%' },
            { label: 'Chats today (24h)', value: '28' },
          ],
          metricColor: 'text-green-600',
        },
      ],
    },
    {
      title: 'Review',
      features: [
        {
          title: 'CRM',
          description: 'Customer details and contact history',
          path: '/crm',
          icon: '🤝',
          primaryMetric: '1.2k',
          primaryLabel: 'contacts',
          secondaryMetrics: [
            { label: 'Last synced to SFDC', value: '8h ago' },
            { label: 'Active', value: '892' },
          ],
          metricColor: 'text-purple-600',
          comingSoon: true,
        },
        {
          title: 'Task Manager',
          description: 'Ensure all agent follow ups are completed',
          path: '/tasks',
          icon: '✅',
          primaryMetric: '8',
          primaryLabel: 'pending',
          secondaryMetrics: [
            { label: 'Due today', value: '3' },
            { label: 'Completed (30d)', value: '15' },
          ],
          metricColor: 'text-yellow-600',
          comingSoon: true,
        },
        {
          title: 'Escalations',
          description: 'Monitor AI escalations to your team',
          path: '/escalations',
          icon: '⚡',
          primaryMetric: '3',
          primaryLabel: 'ongoing',
          secondaryMetrics: [
            { label: 'Mean response time (30d)', value: '10s' },
            { label: 'Escalation rate (30d)', value: '20%' },
          ],
          metricColor: 'text-red-600',
          comingSoon: true,
        },
      ],
    },
  ]

  const handleSignIn = () => {
    // Handle sign in logic
    console.log('Sign in clicked')
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Header onSignIn={handleSignIn} />

      <main className="max-w-7xl mx-auto px-4 py-6">
        <div className="space-y-8">
          {sections.map((section, sectionIndex) => (
            <div key={sectionIndex}>
              <h2 className="text-sm font-medium text-gray-500 uppercase tracking-wider mb-4 px-1">
                {section.title}
              </h2>

              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                {section.features.map((feature, index) => (
                  <FeatureCard
                    key={index}
                    title={feature.title}
                    description={feature.description}
                    icon={feature.icon}
                    primaryMetric={feature.primaryMetric}
                    primaryLabel={feature.primaryLabel}
                    secondaryMetrics={feature.secondaryMetrics}
                    metricColor={feature.metricColor}
                    onClick={() => navigate(feature.path)}
                    comingSoon={feature.comingSoon}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </main>
    </div>
  )
}

export default HomePage
