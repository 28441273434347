import React from 'react'
import Header from '../Header'

interface PhoneConfig {
  phoneNumber: string
  name: string
  description: string
  status: 'active' | 'inactive'
  callsToday: number
  lastCall?: string
}

const ReceptionPage: React.FC = () => {

  const configuredPhones: PhoneConfig[] = [
    {
      phoneNumber: '+14159361302',
      name: 'Insurance company network check',
      description: 'Inbound phone for insurance company to check if doctor in network, or redirect to a supervisor',
      status: 'active',
      callsToday: 24,
      lastCall: '2 minutes ago',
    },
  ]

  const handleAddNewLine = () => {
    alert(
      'This feature is currently in beta. Please email support@hotlines.ai to configure a new phone line for your business.'
    )
  }

  const handleConfigureClick = (e: React.MouseEvent, phoneNumber: string) => {
    e.stopPropagation()
    alert('This feature is currently in beta. Please email support@hotlines.ai to configure your phone line.')
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Header />

      <div className="flex-1 bg-gray-50">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="md:flex md:items-center md:justify-between mb-8">
            <div className="flex-1 min-w-0">
              <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                Inbound Phone Configuration
              </h1>
              <p className="mt-1 text-sm text-gray-500">
                Manage your inbound business phone lines
              </p>
            </div>
            <div className="mt-4 flex md:mt-0 md:ml-4">
              <button
                onClick={handleAddNewLine}
                className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <svg
                  className="h-4 w-4 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 4v16m8-8H4"
                  />
                </svg>
                Add New Phone Line
              </button>
            </div>
          </div>

          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <ul className="divide-y divide-gray-200">
              {configuredPhones.map((phone) => (
                <li key={phone.phoneNumber}>
                  <div
                    className="px-4 py-5 sm:px-6 hover:bg-gray-50 cursor-pointer"
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex-1">
                        <div className="flex items-center">
                          <h3 className="text-lg font-medium text-gray-900">
                            {phone.name}
                          </h3>
                          <span
                            className={`ml-3 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                              phone.status === 'active'
                                ? 'bg-green-100 text-green-800'
                                : 'bg-red-100 text-red-800'
                            }`}
                          >
                            {phone.status === 'active' ? 'Active' : 'Inactive'}
                          </span>
                        </div>
                        <p className="mt-1 text-sm text-gray-500">
                          {phone.phoneNumber}
                        </p>
                        <p className="mt-2 text-sm text-gray-600">
                          {phone.description}
                        </p>
                      </div>
                      <div className="ml-6">
                        <div className="flex items-center">
                          <button
                            onClick={(e) => handleConfigureClick(e, phone.phoneNumber)}
                            className="text-blue-600 hover:text-blue-900 flex items-center"
                          >
                            Configure
                            <svg
                              className="ml-2 h-5 w-5"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M9 5l7 7-7 7"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 grid grid-cols-4 gap-4">
                      <div className="border rounded-lg p-3 bg-gray-50">
                        <div className="text-sm font-medium text-gray-500">
                          Office Hours
                        </div>
                        <div className="mt-1 text-sm text-gray-900">
                          9:00 - 22:00{' '}
                        </div>
                      </div>
                      <div className="border rounded-lg p-3 bg-gray-50">
                        <div className="text-sm font-medium text-gray-500">
                          Redirection
                        </div>
                        <div className="mt-1 text-sm text-gray-900">
                          +919920981996
                        </div>
                      </div>
                      <div className="border rounded-lg p-3 bg-gray-50">
                        <div className="text-sm font-medium text-gray-500">
                          Today's Activity
                        </div>
                        <div className="mt-1 text-sm text-gray-900">
                          {phone.callsToday} calls
                        </div>
                        <div className="text-sm text-gray-500">
                          Last: {phone.lastCall}
                        </div>
                      </div>
                      <div className="border rounded-lg p-3 bg-gray-50">
                        <div className="text-sm font-medium text-gray-500">
                          Performance (30d)
                        </div>
                        <div className="mt-1 text-sm text-green-600">
                          80% automation rate
                        </div>
                        <div className="text-sm text-gray-500">
                          50% answer rate
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {configuredPhones.length === 0 && (
            <div className="text-center py-12">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No phone lines configured
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Get started by adding a new phone line.
              </p>
              <div className="mt-6">
                <button
                  onClick={handleAddNewLine}
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <svg
                    className="h-4 w-4 mr-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                  Add Phone Line
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ReceptionPage
