import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom'
import ChatbotsPage from './components/chatbots/ChatbotsPage'
import ChatInterface from './components/chatbots/ChatInterface'
import DialerPage from './components/dialer/DialerPage'
import HomePage from './components/HomePage'
import LandingPage from './components/LandingPage'
import LoginPage from './components/LoginPage'
import ProfilePage from './components/profile/profilePage'
import ReceptionPage from './components/reception/ReceptionPage'
import { AuthProvider, useAuth } from './contexts/AuthContext'

// Add a loading component
const LoadingComponent = () => <div>Checking authentication...</div>

// Create AppContent component to use the auth context
const AppContent = () => {
  const { session, loading } = useAuth()

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={session ? <HomePage /> : <LandingPage />} />
        <Route
          path="/login"
          element={!session ? <LoginPage /> : <Navigate to="/" />}
        />
        <Route
          path="/dialer/*" // The asterisk is important for nested routes
          element={session ? <DialerPage /> : <Navigate to="/login" />}
        />
        <Route
          path="/reception"
          element={session ? <ReceptionPage /> : <Navigate to="/login" />}
        />
        <Route
          path="/chatbot"
          element={session ? <ChatbotsPage /> : <Navigate to="/login" />}
        />
        <Route
          path="/chatbot/:botId"
          element={<ChatInterface />} // No session check as this is public
        />
        <Route
          path="/profile"
          element={<ProfilePage />} // No session check as this is public
        />
      </Routes>
    </div>
  )
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  )
}

export default App
