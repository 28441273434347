import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

interface Message {
  id: string
  content: string
  sender: 'user' | 'bot'
  timestamp: Date
}

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/api'

const ChatInterface: React.FC = () => {
  const { botId } = useParams()
  const [messages, setMessages] = useState<Message[]>([])
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const messagesEndRef = useRef<null | HTMLDivElement>(null)

  // Mock chatbot data (replace with API call later)
  const [chatbot] = useState({
    name: 'hotlines.ai support',
    description: 'ask any questions about hotlines.ai',
    brandColor: '#2563eb', // Default blue
  })

  // Add initial welcome message
  useEffect(() => {
    const initializeChat = async () => {
      try {
        // Create a new thread
        const response = await fetch(`${API_BASE_URL}/v1/chatbots/thread`, {
          method: 'POST',
        })
        const data = await response.json()
        setThreadId(data.threadId)

        const welcomeMessage: Message = {
          id: 'welcome',
          content: `Hey there! This is Hannah from hotlines.ai. I'm here to help. Where do you work?`,
          sender: 'bot',
          timestamp: new Date(),
        }
        setMessages([welcomeMessage])
      } catch (error) {
        console.error('Error initializing chat:', error)
      }
    }

    initializeChat()
  }, []) // Empty dependency array means this runs once on mount

  // Add threadId state
  const [threadId, setThreadId] = useState<string | null>(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  // Update sendMessage function to use the API
  const sendMessage = async (userMessage: string) => {
    if (!threadId) {
      console.error('No thread ID available')
      return
    }

    const newUserMessage: Message = {
      id: Date.now().toString(),
      content: userMessage,
      sender: 'user',
      timestamp: new Date(),
    }

    setMessages((prev) => [...prev, newUserMessage])
    setInput('')
    setIsLoading(true)

    try {
      const response = await fetch(`${API_BASE_URL}/v1/chatbots/message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          threadId,
          message: userMessage,
        }),
      })

      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.error || 'Failed to get response')
      }

      const botMessage: Message = {
        id: (Date.now() + 1).toString(),
        content: data.response,
        sender: 'bot',
        timestamp: new Date(),
      }

      setMessages((prev) => [...prev, botMessage])
    } catch (error) {
      console.error('Error sending message:', error)
      // Optionally add error message to the chat
      const errorMessage: Message = {
        id: (Date.now() + 1).toString(),
        content: 'Sorry, I encountered an error. Please try again.',
        sender: 'bot',
        timestamp: new Date(),
      }
      setMessages((prev) => [...prev, errorMessage])
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (input.trim() && !isLoading) {
      sendMessage(input.trim())
    }
  }

  // If botId doesn't match, show error
  if (botId !== '12581162025') {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full text-center">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">
            Chatbot Not Available
          </h1>
          <p className="text-gray-600">
            This chatbot does not exist or is no longer available.
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <div className="p-4 shadow-sm" style={{ background: chatbot.brandColor }}>
        <div className="max-w-3xl mx-auto">
          <h1 className="text-2xl font-bold text-white">{chatbot.name}</h1>
          <p className="text-white/80">{chatbot.description}</p>
        </div>
      </div>

      {/* Chat Container */}
      <div className="max-w-3xl mx-auto p-4 flex flex-col h-[calc(100vh-88px)]">
        {/* Messages */}
        <div className="flex-1 overflow-y-auto mb-4">
          {messages.map((message) => (
            <div
              key={message.id}
              className={`mb-4 ${
                message.sender === 'user' ? 'text-right' : 'text-left'
              }`}
            >
              <div
                className={`inline-block p-3 rounded-lg ${
                  message.sender === 'user'
                    ? 'bg-blue-600 text-white'
                    : 'bg-white text-gray-800'
                } max-w-[80%]`}
              >
                {message.content}
              </div>
            </div>
          ))}
          {isLoading && (
            <div className="text-left">
              <div className="inline-block p-3 rounded-lg bg-white text-gray-800">
                <div className="typing-indicator">Typing...</div>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>

        {/* Input Form */}
        <form onSubmit={handleSubmit} className="flex gap-2">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your message..."
            className="flex-1 p-2 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500"
            disabled={isLoading}
          />
          <button
            type="submit"
            disabled={!input.trim() || isLoading}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
          >
            Send
          </button>
        </form>

        {/* Powered by Footer */}
        <div className="mt-4 pt-3 border-t border-gray-200">
          <a
            href="https://hotlines.ai"
            target="_blank"
            rel="noopener noreferrer"
            className="block text-center text-sm text-gray-500 hover:text-gray-700 transition-colors"
          >
            powered by hotlines.ai
          </a>
        </div>
      </div>
    </div>
  )
}

export default ChatInterface
