import React, { useState } from 'react'

interface FeatureCardProps {
  title: string
  description: string
  icon?: string
  primaryMetric?: string
  primaryLabel?: string
  secondaryMetrics?: Array<{
    label: string
    value: string
  }>
  metricColor?: string
  onClick?: () => void
  comingSoon?: boolean
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  title,
  description,
  icon,
  primaryMetric,
  primaryLabel,
  secondaryMetrics,
  metricColor = 'text-gray-600',
  onClick,
  comingSoon = false,
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 })

  const handleMouseMove = (e: React.MouseEvent) => {
    if (comingSoon) {
      setTooltipPosition({ x: e.clientX + 10, y: e.clientY + 10 })
    }
  }

  return (
    <div
      className={`bg-white p-5 rounded-lg transition-all duration-200 relative ${
        comingSoon 
          ? 'opacity-60 cursor-not-allowed' 
          : 'hover:shadow-lg hover:shadow-blue-100/50 hover:scale-[1.02] hover:-translate-y-1 cursor-pointer'
      }`}
      onClick={comingSoon ? undefined : onClick}
      onMouseEnter={() => comingSoon && setShowTooltip(true)}
      onMouseLeave={() => comingSoon && setShowTooltip(false)}
      onMouseMove={handleMouseMove}
    >
      {showTooltip && comingSoon && (
        <div 
          className="fixed z-[9999] bg-gray-900 text-white text-sm p-3 rounded-md shadow-lg max-w-xs"
          style={{ 
            left: `${tooltipPosition.x}px`, 
            top: `${tooltipPosition.y}px`,
          }}
        >
          This feature is coming soon. In the meantime, please contact support@hotlines.ai to have this configured for you
        </div>
      )}
      <div className="flex items-center justify-between mb-3">
        <div className="flex items-center gap-3">
          {icon && (
            <div className="text-2xl flex-shrink-0">
              {icon}
            </div>
          )}
          <div className="flex items-center gap-2">
            <h3 className="text-base font-semibold">{title}</h3>
            {comingSoon && (
              <span className="text-xs font-medium text-gray-500 px-2 py-0.5 bg-gray-100 rounded-full">
                Coming Soon
              </span>
            )}
          </div>
        </div>
        {primaryMetric && (
          <div className={`text-lg font-semibold ${comingSoon ? 'text-gray-400' : metricColor}`}>
            {primaryMetric}
            <span className="text-xs font-medium text-gray-500 ml-1">
              {primaryLabel}
            </span>
          </div>
        )}
      </div>
      <p className="text-sm text-gray-600 leading-snug pl-9 mb-4">{description}</p>
      {secondaryMetrics && (
        <div className="grid grid-cols-2 gap-4 pl-9">
          {secondaryMetrics.map((metric, index) => (
            <div key={index} className="flex flex-col">
              <span className="text-xs font-medium text-gray-500">
                {metric.label}
              </span>
              <span className={`text-sm font-medium ${comingSoon ? 'text-gray-400' : metricColor}`}>
                {metric.value}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default FeatureCard
