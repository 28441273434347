import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'

interface Batch {
  id: number
  batch_name: string
  created_at: string
  total_calls: number
}

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/api'

const BatchesList: React.FC = () => {
  const navigate = useNavigate()
  const { session } = useAuth()
  const [batches, setBatches] = useState<Batch[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchBatches = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/v1/dialer/batches`, {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        })

        if (!response.ok) throw new Error('Failed to fetch batches')

        const data = await response.json()
        const sortedBatches = data.batches.sort(
          (a: Batch, b: Batch) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
        setBatches(sortedBatches)
      } catch (err) {
        setError('Failed to load batches')
        console.error(err)
      } finally {
        setLoading(false)
      }
    }

    fetchBatches()
  }, [session])

  const handleBatchClick = (batchId: number) => {
    navigate(`/dialer/calls?batch=${batchId}`)
  }

  if (loading) return <div className="p-6">Loading...</div>
  if (error) return <div className="p-6 text-red-500">{error}</div>

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">Batches</h1>
        <button
          onClick={() => navigate('/dialer/new-batch')}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
        >
          New Batch
        </button>
      </div>

      <div className="bg-white rounded-lg shadow">
        <table className="min-w-full">
          <thead>
            <tr className="border-b">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Total Calls
              </th>
            </tr>
          </thead>
          <tbody>
            {batches.map((batch) => (
              <tr
                key={batch.id}
                className="border-b hover:bg-gray-50 cursor-pointer"
                onClick={() => handleBatchClick(batch.id)}
              >
                <td className="px-6 py-4">{batch.batch_name}</td>
                <td className="px-6 py-4">
                  {new Date(batch.created_at).toLocaleString()}
                </td>
                <td className="px-6 py-4">{batch.total_calls}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default BatchesList
