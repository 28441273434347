import React from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../assets/png/favicon-8.png'
import { useAuth } from '../contexts/AuthContext'
import ProfileIndicator from './ProfileIndicator'

interface HeaderProps {
  onSignIn?: () => void
}

const Header: React.FC<HeaderProps> = ({ onSignIn }) => {
  const { session, signOut } = useAuth()
  const navigate = useNavigate()

  // // Function to navigate to the client app - disabled for now
  // const goToApp = () => {
  //   const currentHostname = window.location.hostname;
  //   let destinationUrl;

  //   if (currentHostname === 'localhost') {
  //     // If running locally, redirect to port 3000
  //     destinationUrl = 'http://localhost:3000';
  //   } else {
  //     // In production, strip 'console.' from the hostname
  //     const clientUrl = currentHostname.replace('console.', '');
  //     destinationUrl = `https://${clientUrl}`;
  //   }

  //   window.location.href = destinationUrl;
  // };

  return (
    <header
      className="shadow-sm"
      style={{
        background: 'linear-gradient(to right, #EDF5FE, #F4F2FB, #FBF0F7)',
      }}
    >
      <div className="max-w-7xl mx-auto px-4 py-4 flex items-center justify-between">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => navigate('/')}
        >
          <img src={logo} alt="hotlines.ai" className="h-8 w-8" />
          <span className="ml-2 text-xl font-semibold">
            hotlines.ai console
          </span>
        </div>
        <div className="flex items-center gap-4">
          {/* <button
            onClick={goToApp}
            className="text-gray-600 px-4 py-2 rounded-lg hover:bg-gray-100 transition-colors"
          >
            Consumer App
          </button> */}
          {session ? (
            <ProfileIndicator session={session} onSignOut={signOut} />
          ) : (
            <button
              onClick={onSignIn}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
            >
              Sign In
            </button>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
