import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/api'

interface Agent {
  id: number
  name: string
  external_agent_id: string | null
  variables: string[]
  provider: 'RETELL' | 'HOTLINES'
}

const UI_TO_BACKEND_PROVIDER = {
  EXTERNAL: 'RETELL',
  HOTLINES: 'HOTLINES',
} as const

const NewBatch: React.FC = () => {
  const navigate = useNavigate()
  const { session } = useAuth()
  const [file, setFile] = useState<File | null>(null)
  const [batchName, setBatchName] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const [provider, setProvider] = useState<'EXTERNAL' | 'HOTLINES'>('EXTERNAL')
  const [agents, setAgents] = useState<Agent[]>([])
  const [loadingAgents, setLoadingAgents] = useState(false)
  const [selectedAgentId, setSelectedAgentId] = useState<string>('')
  const [selectedAgentVariables, setSelectedAgentVariables] = useState<
    string[]
  >([])

  // Update the state to include fromNumber
  const [fromNumber, setFromNumber] = useState('+14159361302') // Initialize with default value

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0])
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError(null)
    setLoading(true)

    const selectedAgent = agents.find(
      (agent) => agent.id.toString() === selectedAgentId
    )
    const externalAgentId = selectedAgent?.external_agent_id //TODO: for hotlines agents there may not be an external agent id

    if (!file || !batchName) {
      setError('Batch name and contacts file are required')
      setLoading(false)
      return
    }

    if (!provider) {
      setError('Provider is required')
    }
    if (!externalAgentId) {
      setError('Please select an agent')
      setLoading(false)
      return
    }

    const formDataPayload = new FormData()
    formDataPayload.append('file', file)
    formDataPayload.append('batchName', batchName)
    formDataPayload.append('agent_id', externalAgentId)
    formDataPayload.append('from_number', fromNumber)
    formDataPayload.append('provider', UI_TO_BACKEND_PROVIDER[provider])

    try {
      const response = await fetch(`${API_BASE_URL}/v1/dialer/batches`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
        body: formDataPayload,
      })

      if (!response.ok) {
        const data = await response.json()
        throw new Error(data.error || 'Failed to create batch')
      }

      navigate('/dialer/batches')
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create batch')
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const fetchAgents = useCallback(
    async (provider?: 'EXTERNAL' | 'HOTLINES') => {
      if (!session) return

      try {
        setLoadingAgents(true)
        const url = new URL(`${API_BASE_URL}/v1/dialer/agents`)
        if (provider) {
          url.searchParams.append('provider', UI_TO_BACKEND_PROVIDER[provider])
        }

        const response = await fetch(url.toString(), {
          headers: {
            Authorization: `Bearer ${session.access_token}`,
          },
        })

        if (!response.ok) throw new Error('Failed to fetch agents')
        const data = await response.json()
        setAgents(data.agents)
      } catch (err) {
        console.error('Error fetching agents:', err)
      } finally {
        setLoadingAgents(false)
      }
    },
    [session]
  )

  useEffect(() => {
    fetchAgents(provider)
  }, [provider, session, fetchAgents])

  useEffect(() => {
    const selectedAgent = agents.find(
      (agent) => agent.id.toString() === selectedAgentId
    )
    setSelectedAgentVariables(selectedAgent?.variables || [])
  }, [selectedAgentId, agents])

  return (
    <div className="p-6 max-w-2xl mx-auto">
      <div className="mb-6">
        <h1 className="text-2xl font-semibold">Create New Batch</h1>
        <p className="text-gray-600 mt-2">
          Upload a list of contacts to start processing with our AI agent.
        </p>
      </div>

      {error && (
        <div className="mb-4 p-4 text-red-700 bg-red-100 rounded-md">
          {error}
        </div>
      )}

      <form
        onSubmit={handleSubmit}
        className="space-y-6 bg-white p-6 rounded-lg shadow"
      >
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Batch Name
          </label>
          <input
            type="text"
            value={batchName}
            onChange={(e) => setBatchName(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            From Number
          </label>
          <input
            type="text"
            value={fromNumber}
            onChange={(e) => setFromNumber(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Provider
          </label>
          <select
            name="provider"
            value={provider}
            onChange={(e) =>
              setProvider(e.target.value as 'EXTERNAL' | 'HOTLINES')
            }
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white"
            required
          >
            <option value="EXTERNAL">EXTERNAL</option>
            <option value="HOTLINES" disabled>
              HOTLINES (Coming Soon)
            </option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Agent
          </label>
          <select
            name="agent_id"
            value={selectedAgentId}
            onChange={(e) => setSelectedAgentId(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white"
            required
          >
            <option value="">Select an agent</option>
            {agents.map((agent) => (
              <option key={agent.id} value={agent.id.toString()}>
                {agent.name}
              </option>
            ))}
          </select>
          {loadingAgents && (
            <p className="mt-1 text-sm text-gray-500">Loading agents...</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Upload Contacts (CSV)
          </label>
          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
            <div className="space-y-1 text-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-sm text-gray-600 justify-center">
                <label className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500">
                  <span>Upload a file</span>
                  <input
                    type="file"
                    className="sr-only"
                    accept=".csv"
                    onChange={handleFileChange}
                    required
                  />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs text-gray-500">
                CSV should have the following columns:{' '}
                <strong>phone_number</strong> followed by one column per
                variable required by the agent
                {selectedAgentVariables.length > 0 && (
                  <> ({selectedAgentVariables.join(', ')})</>
                )}
              </p>
            </div>
          </div>
          {file && (
            <p className="mt-2 text-sm text-gray-600">
              Selected file: {file.name}
            </p>
          )}
        </div>

        <div className="flex justify-end gap-4">
          <button
            type="button"
            onClick={() => navigate('/dialer/batches')}
            disabled={loading}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className={`px-4 py-2 text-sm font-medium text-white ${
              loading ? 'bg-blue-400' : 'bg-blue-600 hover:bg-blue-700'
            } rounded-md`}
          >
            {loading ? 'Creating...' : 'Create Batch'}
          </button>
        </div>
      </form>
    </div>
  )
}

export default NewBatch
