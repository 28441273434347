import React from 'react'
import { Session } from '@supabase/supabase-js'

interface ProfileIndicatorProps {
  session: Session | null
  onSignOut: () => void
}

const ProfileIndicator: React.FC<ProfileIndicatorProps> = ({
  session,
  onSignOut,
}) => {
  if (!session) return null

  // Determine initials from email or user metadata
  let initials = ''
  if (session.user.email) {
    initials = session.user.email.charAt(0).toUpperCase()
  }

  return (
    <div 
      className="flex items-center"
      onClick={onSignOut}
      role="button"
      title="Sign out"
    >
      <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center cursor-pointer hover:opacity-80 overflow-hidden">
        {session.user.user_metadata.avatar_url ? (
          <img
            src={session.user.user_metadata.avatar_url}
            alt={session.user.email}
            className="w-full h-full object-cover"
          />
        ) : (
          <span className="text-sm font-medium">{initials}</span>
        )}
      </div>
    </div>
  )
}

export default ProfileIndicator 