import React from 'react'

interface KnowledgeSource {
  name: string
  type: 'url' | 'pdf' | 'docx'
}

interface AgentConfig {
  name: string
  description: string
  knowledgeConnected: KnowledgeSource[]
  totalCalls: number
  effectiveRate: number
  status: 'active' | 'inactive'
}

const AgentsPage: React.FC = () => {
  const agents: AgentConfig[] = [
    {
      name: 'White Lotus Check-In Confirmation',
      description: 'Calls guests with upcoming stays at the White Lotus Koh Samui to check if they are arriving and offers airport transfers, spa treatments, and other services.',
      knowledgeConnected: [
        { name: 'whitelotus.com/kohsamui', type: 'url' },
        { name: 'Hotel Brochure - White Lotus', type: 'pdf' },
        { name: 'Spa Menu', type: 'docx' }
      ],
      totalCalls: 156,
      effectiveRate: 78,
      status: 'active'
    }
  ]

  const handleAddNewAgent = () => {
    alert('This feature is coming soon! Please email support@hotlines.ai to configure a new agent for your business.')
  }

  const handleConfigureAgent = (e: React.MouseEvent) => {
    e.stopPropagation()
    alert('This feature is coming soon! Please email support@hotlines.ai to configure your agent.')
  }

  const getFileIcon = (type: 'url' | 'pdf' | 'docx') => {
    switch (type) {
      case 'url':
        return (
          <svg className="w-4 h-4 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
          </svg>
        )
      case 'pdf':
        return (
          <svg className="w-4 h-4 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
          </svg>
        )
      case 'docx':
        return (
          <svg className="w-4 h-4 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
          </svg>
        )
    }
  }

  return (
    <div className="max-w-6xl mx-auto">
      <div className="md:flex md:items-center md:justify-between mb-8">
        <div className="flex-1 min-w-0">
          <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            AI Agents
          </h1>
          <p className="mt-1 text-sm text-gray-500">
            Manage your AI agents for outbound calling
          </p>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <button
            onClick={handleAddNewAgent}
            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <svg
              className="h-4 w-4 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v16m8-8H4"
              />
            </svg>
            Add New Agent
          </button>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <ul className="divide-y divide-gray-200">
          {agents.map((agent) => (
            <li key={agent.name}>
              <div className="px-4 py-5 sm:px-6">
                <div className="flex items-center justify-between">
                  <div className="flex-1">
                    <div className="flex items-center">
                      <h3 className="text-lg font-medium text-gray-900">
                        {agent.name}
                      </h3>
                      <span
                        className={`ml-3 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                          agent.status === 'active'
                            ? 'bg-green-100 text-green-800'
                            : 'bg-red-100 text-red-800'
                        }`}
                      >
                        {agent.status === 'active' ? 'Active' : 'Inactive'}
                      </span>
                    </div>
                    <p className="mt-2 text-sm text-gray-600">
                      {agent.description.length > 100
                        ? `${agent.description.slice(0, 100)}...`
                        : agent.description}
                    </p>
                  </div>
                  <div className="ml-6">
                    <button
                      onClick={handleConfigureAgent}
                      className="text-blue-600 hover:text-blue-900 flex items-center"
                    >
                      Configure
                      <svg
                        className="ml-2 h-5 w-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <div className="mt-4 grid grid-cols-3 gap-4">
                  <div className="border rounded-lg p-3 bg-gray-50">
                    <div className="text-sm font-medium text-gray-500">
                      Knowledge Connected
                    </div>
                    <div className="mt-1 text-sm text-gray-900">
                      {agent.knowledgeConnected.map((knowledge, idx) => (
                        <div key={idx} className="text-sm flex items-center space-x-2 mb-1">
                          {getFileIcon(knowledge.type)}
                          <span> {knowledge.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="border rounded-lg p-3 bg-gray-50">
                    <div className="text-sm font-medium text-gray-500">
                      Total Calls
                    </div>
                    <div className="mt-1 text-sm text-gray-900">
                      {agent.totalCalls} calls completed
                    </div>
                  </div>
                  <div className="border rounded-lg p-3 bg-gray-50">
                    <div className="text-sm font-medium text-gray-500">
                      Performance
                    </div>
                    <div className="mt-1 text-sm text-green-600">
                      {agent.effectiveRate}% effective conversation rate
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {agents.length === 0 && (
        <div className="text-center py-12">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            No agents configured
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Get started by adding a new agent.
          </p>
          <div className="mt-6">
            <button
              onClick={handleAddNewAgent}
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <svg
                className="h-4 w-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 4v16m8-8H4"
                />
              </svg>
              Add Agent
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default AgentsPage 