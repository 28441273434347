import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../Header'

interface Chatbot {
  id: string
  name: string
  description: string
  shareUrl: string
  status: 'active' | 'inactive'
  messagesLast24h: number
  lastMessage?: string
  avgResponseTime: string
  satisfactionRate: string
}

const ChatbotsPage: React.FC = () => {
  const [chatbots, setChatbots] = useState<Chatbot[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // TODO: Replace with actual API call
    const fetchChatbots = async () => {
      // Temporary mock data
      setChatbots([
        {
          id: '12581162025',
          name: 'hotlines.ai support',
          description: 'ask any questions about hotlines.ai',
          shareUrl: `/chatbot/12581162025`,
          status: 'active',
          messagesLast24h: 145,
          lastMessage: '5 minutes ago',
          avgResponseTime: '1.2s',
          satisfactionRate: '92%'
        },
      ])
      setIsLoading(false)
    }

    fetchChatbots()
  }, [])

  const copyShareLink = (shareUrl: string) => {
    const fullUrl = `${window.location.origin}${shareUrl}`
    navigator.clipboard.writeText(fullUrl)
    // TODO: Add toast notification
  }

  const getEmbedCode = (botId: string) => {
    return `<script src="${window.location.origin}/embed.js"></script>`
  }

  const handleAddNewChatbot = () => {
    alert('This feature is currently in beta. Please email support@hotlines.ai to configure a new chatbot for your business.')
  }

  const handleConfigureClick = (e: React.MouseEvent, botId: string) => {
    e.stopPropagation()
    alert('This feature is currently in beta. Please email support@hotlines.ai to configure your chatbot.')
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Header />

      <div className="flex-1 bg-gray-50">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="md:flex md:items-center md:justify-between mb-8">
            <div className="flex-1 min-w-0">
              <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                Your Chatbots
              </h1>
              <p className="mt-1 text-sm text-gray-500">
                Manage your AI chatbot assistants
              </p>
            </div>
            <div className="mt-4 flex md:mt-0 md:ml-4">
              <button
                onClick={handleAddNewChatbot}
                className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <svg
                  className="h-4 w-4 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 4v16m8-8H4"
                  />
                </svg>
                Create New Chatbot
              </button>
            </div>
          </div>

          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <ul className="divide-y divide-gray-200">
              {chatbots.map((chatbot) => (
                <li key={chatbot.id}>
                  <div className="px-4 py-5 sm:px-6 hover:bg-gray-50">
                    <div className="flex items-center justify-between">
                      <div className="flex-1">
                        <div className="flex items-center">
                          <h3 className="text-lg font-medium text-gray-900">
                            {chatbot.name}
                          </h3>
                          <span
                            className={`ml-3 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                              chatbot.status === 'active'
                                ? 'bg-green-100 text-green-800'
                                : 'bg-red-100 text-red-800'
                            }`}
                          >
                            {chatbot.status === 'active' ? 'Active' : 'Inactive'}
                          </span>
                        </div>
                        <p className="mt-1 text-sm text-gray-500">
                          {chatbot.description}
                        </p>
                      </div>
                      <div className="ml-6">
                        <div className="flex items-center space-x-4">
                          <button
                            onClick={() => copyShareLink(chatbot.shareUrl)}
                            className="text-blue-600 hover:text-blue-800"
                          >
                            Share
                          </button>
                          <Link
                            to={chatbot.shareUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:text-blue-800"
                          >
                            Preview
                          </Link>
                          <button
                            onClick={(e) => handleConfigureClick(e, chatbot.id)}
                            className="text-blue-600 hover:text-blue-900 flex items-center"
                          >
                            Configure
                            <svg
                              className="ml-2 h-5 w-5"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M9 5l7 7-7 7"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 grid grid-cols-4 gap-4">
                      <div className="border rounded-lg p-3 bg-gray-50">
                        <div className="text-sm font-medium text-gray-500">
                          Messages (24h)
                        </div>
                        <div className="mt-1 text-sm text-gray-900">
                          {chatbot.messagesLast24h} messages
                        </div>
                        <div className="text-sm text-gray-500">
                          Last: {chatbot.lastMessage}
                        </div>
                      </div>
                      <div className="border rounded-lg p-3 bg-gray-50">
                        <div className="text-sm font-medium text-gray-500">
                          Response Time
                        </div>
                        <div className="mt-1 text-sm text-gray-900">
                          {chatbot.avgResponseTime}
                        </div>
                        <div className="text-sm text-gray-500">
                          Average
                        </div>
                      </div>
                      <div className="border rounded-lg p-3 bg-gray-50 col-span-2">
                        <div className="text-sm font-medium text-gray-500">
                          Embed Code
                        </div>
                        <div className="mt-2 p-2 bg-gray-100 rounded text-sm font-mono break-all">
                          {getEmbedCode(chatbot.id)}
                        </div>
                        <button
                          onClick={() => {
                            navigator.clipboard.writeText(getEmbedCode(chatbot.id))
                          }}
                          className="mt-2 text-sm text-blue-600 hover:text-blue-800 flex items-center"
                        >
                          <svg
                            className="h-4 w-4 mr-1"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                            />
                          </svg>
                          Copy
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {chatbots.length === 0 && (
            <div className="text-center py-12">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                />
              </svg>
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No chatbots configured
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Get started by creating a new chatbot.
              </p>
              <div className="mt-6">
                <button
                  onClick={handleAddNewChatbot}
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <svg
                    className="h-4 w-4 mr-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                  Create Chatbot
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ChatbotsPage
