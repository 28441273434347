import React, { useState } from 'react'
import Header from '../Header'

const ProfilePage: React.FC = () => {
  const [businessName, setBusinessName] = useState('Hangawi Restaurant')
  const [description, setDescription] = useState(
    'Upscale vegetarian menu of creative, gourmet Korean fare in a tranquil space where shoes come off at the door.'
  )
  const [businessType, setBusinessType] = useState('Restaurant')

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="flex-1 bg-gray-50">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                Profile Settings
              </h1>
              <p className="mt-1 text-sm text-gray-500">
                Manage your business profile settings.
              </p>
            </div>
            <a
              href="https://hotlines.ai/business/2"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors duration-300"
            >
              View Site
            </a>
          </div>

          <div className="bg-white shadow rounded-lg p-6 mt-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Business Name
                </label>
                <input
                  type="text"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Business Type
                </label>
                <select
                  value={businessType}
                  onChange={(e) => setBusinessType(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                >
                  <option>Restaurant</option>
                  <option>Service</option>
                  <option>Manufacturing</option>
                  <option>Retail</option>
                </select>
              </div>
            </div>
            <div className="mt-6">
              <label className="block text-sm font-medium text-gray-700">
                Business Description
              </label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
              />
            </div>
            <div className="mt-6">
              <label className="block text-sm font-medium text-gray-700">
                Cover Image
              </label>
              <span>Hangawi.png</span>
              <button className="ml-4 bg-gray-300 text-white px-1 rounded-md py-1 hover:bg-gray-700">
                Upload Image
              </button>
            </div>
          </div>

          {/* Placeholder for carousels */}
          <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-white shadow rounded-lg p-6 relative">
              {/* Edit Button */}
              <button
                className="absolute top-4 right-4 bg-blue-500 text-gray-700 rounded-md p-2 hover:bg-blue-600 transition"
                onClick={() => console.log('Edit carousel')}
                aria-label="Edit carousel"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M12 20h9" />
                  <path d="M16.5 3.5a2.12 2.12 0 1 1 3 3L7 19l-4 1 1-4Z" />
                </svg>
              </button>

              {/* Card Content */}
              <h2 className="text-lg font-semibold">Custom Carousel 1</h2>
              <p className="text-sm text-gray-500">
                Add items to your carousel
              </p>
              <span className="text-sm text-gray-700">6 images uploaded</span>
            </div>
            <div className="bg-white shadow rounded-lg p-6 relative">
              {/* Edit Button */}
              <button
                className="absolute top-4 right-4 bg-blue-500 text-gray-700 rounded-md p-2 hover:bg-blue-600 transition"
                onClick={() => console.log('Edit carousel')}
                aria-label="Edit carousel"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M12 20h9" />
                  <path d="M16.5 3.5a2.12 2.12 0 1 1 3 3L7 19l-4 1 1-4Z" />
                </svg>
              </button>

              {/* Card Content */}
              <h2 className="text-lg font-semibold">Custom Carousel 1</h2>
              <p className="text-sm text-gray-500">
                Add items to your carousel
              </p>
              <span className="text-sm text-gray-700">5 images uploaded</span>
            </div>
          </div>

          {/* Actions and Card Types */}
          <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-white shadow rounded-lg p-6 relative">
              {/* Plus Button */}
              <button
                className="absolute top-4 right-4 bg-blue-500 text-white rounded-md p-2 hover:bg-blue-600 transition"
                onClick={() => console.log('Add new action')}
                aria-label="Add new action"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M12 5v14M5 12h14" />
                </svg>
              </button>

              {/* Card Title */}
              <h2 className="text-lg font-semibold">Actions</h2>
              <p className="text-sm text-gray-500">
                Define actions for your business
              </p>

              {/* Action List */}
              <ul className="mt-4 space-y-2">
                <li className="flex items-center">
                  <span className="text-sm bg-blue-300 text-white px-2 rounded-md py-1 flex items-center">
                    Book a table
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 ml-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                </li>
                <li className="flex items-center">
                  <span className="text-sm bg-blue-300 text-white px-2 rounded-md py-1 flex items-center">
                    Speak to a human
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 ml-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                </li>
              </ul>
            </div>
            <div className="bg-white shadow rounded-lg p-6 relative">
              {/* Plus Button */}
              <button
                className="absolute top-4 right-4 bg-blue-500 text-white rounded-md p-2 hover:bg-blue-600 transition"
                onClick={() => console.log('Add new card')}
                aria-label="Add new card"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M12 5v14M5 12h14" />
                </svg>
              </button>

              {/* Main Title */}
              <h2 className="text-lg font-semibold">Types of Cards</h2>
              <p className="text-sm text-gray-500 mb-4">
                Select card types for customer interactions
              </p>

              {/* Embedded Table Card */}
              <div className="bg-gray-100 shadow-inner rounded-lg p-4">
                <h3 className="text-md font-semibold text-gray-800 mb-2">
                  Table Card
                </h3>

                {/* Table Data */}
                <div className="flex flex-row space-x-2 text-sm text-gray-700">
                  <div>Date</div>
                  <div>Time</div>
                  <div>People</div>
                  <div>Status</div>
                </div>

                <ul className="mt-4 space-y-2">
                  <li className="flex items-center">
                    <span className="text-sm bg-blue-300 text-white px-2 rounded-md py-1 flex items-center">
                      Change
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 ml-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </span>
                  </li>
                  <li className="flex items-center">
                    <span className="text-sm bg-blue-300 text-white px-2 rounded-md py-1 flex items-center">
                      Cancel
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 ml-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfilePage
